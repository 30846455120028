import { ModeType } from 'interfaces';
import { useAuth } from 'modules/auth/providers/AuthProvider';
import { useClient } from 'modules/settings/providers/ClientProvider';
import { ChangeEvent } from 'react';
import { isUrlAllowed } from 'utils/hooks/useAuthRouter';

import NavbarLink from '../NavbarLink';
import { NavbarLinksWrapper } from './styles';

interface LinkType {
  link: string;
  label: string;
  hideFrom: string[];
  isAuth?: boolean;
  isLogoutLink?: boolean;
}

const appLinks: LinkType[] = [
  {
    label: 'Offerings',
    link: '/current-offerings',
    hideFrom: [
      'core',
      'metro',
      'jvm',
      'solyco',
      'rmr',
      'wellspring',
      'live oak',
    ],
  },
  {
    label: 'Investments',
    link: '/dashboard',
    hideFrom: [],
    isAuth: true,
  },
  {
    label: 'Documents',
    link: '/documents',
    hideFrom: [],
    isAuth: true,
  },
  {
    label: 'Tax Center',
    link: '/tax-center',
    hideFrom: ['rmr', 'wellspring', 'live oak'],
    isAuth: true,
  },
  {
    label: 'Settings',
    link: '/settings',
    hideFrom: ['wellspring', 'live oak'],
    isAuth: true,
  },
  {
    label: 'CRM - Offerings',
    link: '/crm/offerings',
    isAuth: true,

    hideFrom: ['solyco'],
  },
  {
    label: 'CRM - Investors',
    link: '/crm/investors',
    isAuth: true,
    hideFrom: [],
  },
  {
    label: 'PM - Manager Dashboard',
    link: '/admin/dashboard',
    isAuth: true,
    hideFrom: [],
  },
  {
    label: 'PM - Offerings',
    link: '/admin/offerings',
    isAuth: true,
    hideFrom: [],
  },
  {
    label: 'PM - Investors',
    link: '/admin/investors',
    isAuth: true,
    hideFrom: [],
  },
  {
    label: 'PM - Investor Edits',
    link: '/admin/investorEdits',
    isAuth: true,
    hideFrom: [],
  },
  {
    label: 'PM - Submissions',
    link: '/admin/submissions',
    isAuth: true,
    hideFrom: [],
  },
  {
    label: 'PM - Settings',
    link: '/admin/settings',
    isAuth: true,
    hideFrom: [],
  },
  {
    label: 'Sign Out',
    link: '/signout',
    hideFrom: [],
    isAuth: true,
    isLogoutLink: true,
  },
  {
    label: 'Sign In',
    link: '/',
    hideFrom: [],
    isAuth: false,
  },
];

interface NavbarLinksProps {
  type?: string;
  callback?: () => void;
}

export function NavbarLinks({ callback, type = 'app' }: NavbarLinksProps) {
  const { client } = useClient();
  const { state, logout } = useAuth();

  const filterLinks = (links: LinkType[], routes: string[]) => {
    if (routes.length < 1) {
      return links.filter(
        (link) => !link.isAuth && !link.hideFrom.includes(client)
      );
    }
    return links.filter(
      (link) =>
        (isUrlAllowed(link.link, routes) && link.isAuth) || link.isLogoutLink
    );
  };

  const handleLogout = (event?: ChangeEvent) => {
    event?.preventDefault();
    logout();
    callback && callback();
  };

  const mode: ModeType = type === 'app' ? 'light' : 'dark';
  const links = filterLinks(appLinks, state.user?.routes ?? []);

  return (
    <NavbarLinksWrapper>
      {links.map((link, index) => (
        <NavbarLink
          key={index}
          label={link.label}
          href={link.link}
          handleClick={link.isLogoutLink ? handleLogout : callback}
          mode={mode}
        />
      ))}
    </NavbarLinksWrapper>
  );
}
